
import { CATEGORY_ICONS } from '../_config';

//To change the category icon of the marker
const CATEGORY_ICON_SVG_PLACE = [
  '<svg width="60px" height="78px" viewBox="0 0 60 78" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">',
      '<defs>',
          '<filter x="-6.7%" y="-5.3%" width="113.3%" height="110.7%" filterUnits="objectBoundingBox" id="filter-1">',
              '<feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>',
              '<feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>',
              '<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>',
              '<feMerge>',
                  '<feMergeNode in="shadowMatrixOuter1"></feMergeNode>',
                  '<feMergeNode in="SourceGraphic"></feMergeNode>',
              '</feMerge>',
          '</filter>',
          '<path d="M23.0787999,58.7488013 C9.84693114,55.6478043 0,43.8513786 0,29.7725183 C0,13.3296105 13.4314575,0 30,0 C46.5685425,0 60,13.3296105 60,29.7725183 C60,43.8513786 50.1530689,55.6478043 36.9212001,58.7488013 L30,67 L23.0787999,58.7488013 Z M30,75 C28.3431458,75 27,73.6568542 27,72 C27,70.3431458 28.3431458,69 30,69 C31.6568542,69 33,70.3431458 33,72 C33,73.6568542 31.6568542,75 30,75 Z" id="path-2"></path>',
          '<path d="{{CATEGORY_ICON}}" id="path-4"></path>',
      '</defs>',
      '<g id="Icons/Map-Marker-Supermarche-Copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">',
          '<g id="Map/Marker/Selected">',
              '<g id="Group-3-Copy" filter="url(#filter-1)" stroke-width="1" fill-rule="evenodd">',
                  '<g id="✱-/-Color-/-Icons-/-Error">',
                      '<mask id="mask-3" fill="white">',
                        '  <use xlink:href="#path-2"></use>',
                      '</mask>',
                      '<use id="Mask" fill="{{CATEGORY_COLOR}}" xlink:href="#path-2"></use>',
                      '<g id="✱-/-Color-/-Icons-/-Gray-Light" mask="url(#mask-3)" fill="{{CATEGORY_COLOR}}">',
                          '<rect id="Rectangle" x="0" y="0" width="60" height="78"></rect>',
                      '</g>',
                  '</g>',
                  '<path d="M23.0787999,58.7488013 C9.84693114,55.6478043 0,43.8513786 0,29.7725183 C0,13.3296105 13.4314575,0 30,0 C46.5685425,0 60,13.3296105 60,29.7725183 C60,43.8513786 50.1530689,55.6478043 36.9212001,58.7488013 L30,67 L23.0787999,58.7488013 Z" id="Combined-Shape-Copy-2"></path>',
              '</g>',
              '<g id="Map/Marker/Content/Icon">',
                  '<g id="Icons/supermarches/Solid" transform="translate(16.875000, 16.750000)">',
                    '  <mask id="mask-5" fill="white">',
                    '      <use xlink:href="#path-4"></use>',
                    '  </mask>',
                    '  <use id="Mask" fill="white" fill-rule="nonzero" xlink:href="#path-4"></use>',
                    '  <g id="✱-/-Color-/-Icons-/-White" mask="url(#mask-5)" fill="#FFFFFF" fill-rule="evenodd">',
                    '      <rect id="Rectangle" x="0" y="0" width="26" height="26"></rect>',
                    '  </g>',
                   '</g>',
              '</g>',
          '</g>',
      '</g>',
  '</svg>',
                ].join('\n');

const CATEGORY_ICON_SVG_EVENT = [
  '<svg width="60px" height="75px" viewBox="0 0 60 75" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">',
      '<title>Map-Marker-Supermarche@2x</title>',
      '<desc>Created with Sketch.</desc>',
      '<defs>',
          '<path d="M23.0787999,58.7488013 C9.84693114,55.6478043 0,43.8513786 0,29.7725183 C0,13.3296105 13.4314575,0 30,0 C46.5685425,0 60,13.3296105 60,29.7725183 C60,43.8513786 50.1530689,55.6478043 36.9212001,58.7488013 L30,67 L23.0787999,58.7488013 Z M30,75 C28.3431458,75 27,73.6568542 27,72 C27,70.3431458 28.3431458,69 30,69 C31.6568542,69 33,70.3431458 33,72 C33,73.6568542 31.6568542,75 30,75 Z" id="path-1"></path>',
          '<path d="{{CATEGORY_ICON}}" id="path-3"></path>',
      '</defs>',
      '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">',
          '<g id="Map-Marker-Supermarche">',
              '<g id="Group-3-Copy">',
                  '<g id="✱-/-Color-/-Icons-/-Error">',
                      '<g id="Mask" fill="{{CATEGORY_COLOR}}" fill-rule="nonzero">',
                        '  <path d="M23.0787999,58.7488013 C9.84693114,55.6478043 0,43.8513786 0,29.7725183 C0,13.3296105 13.4314575,0 30,0 C46.5685425,0 60,13.3296105 60,29.7725183 C60,43.8513786 50.1530689,55.6478043 36.9212001,58.7488013 L30,67 L23.0787999,58.7488013 Z M30,75 C28.3431458,75 27,73.6568542 27,72 C27,70.3431458 28.3431458,69 30,69 C31.6568542,69 33,70.3431458 33,72 C33,73.6568542 31.6568542,75 30,75 Z" id="path-2"></path>',
                      '</g>',
                      '<g id="✱-/-Color-/-Icons-/-Gray-Light-Clipped">',
                          '<mask id="mask-2" fill="white">',
                          '    <use xlink:href="#path-1"></use>',
                          '</mask>',
                          '<g id="path-2"></g>',
                          '<g id="✱-/-Color-/-Icons-/-Gray-Light" mask="url(#mask-2)" fill="{{CATEGORY_COLOR}}">',
                              '<rect id="Rectangle" x="0" y="0" width="60" height="78"></rect>',
                          '</g>',
                      '</g>',
                  '</g>',
                  '<path d="M23.0787999,58.7488013 C9.84693114,55.6478043 0,43.8513786 0,29.7725183 C0,13.3296105 13.4314575,0 30,0 C46.5685425,0 60,13.3296105 60,29.7725183 C60,43.8513786 50.1530689,55.6478043 36.9212001,58.7488013 L30,67 L23.0787999,58.7488013 Z" id="Combined-Shape-Copy-2"></path>',
              '</g>',
              '<g id="✱-/-Color-/-Icons-/-Primary-Dark" transform="translate(21.000000, 20.000000)">',
                  '<mask id="mask-4" fill="white">',
                      '<use xlink:href="#path-3"></use>',
                  '</mask>',
                  '<use id="Mask" fill="#000000" xlink:href="#path-3"></use>',
                  '<g id="✱-/-Color-/-Icons-/-White" mask="url(#mask-4)" fill="#FFFFFF">',
                      '<g transform="translate(-3.000000, -2.000000)" id="Rectangle">',
                          '<rect x="0" y="0" width="24" height="24"></rect>',
                      '</g>',
                  '</g>',
              '</g>',
          '</g>',
      '</g>',
  '</svg>',
                ].join('\n');

export function replaceMarkerIcon(marker, categories){
  let svg_icon_template = CATEGORY_ICON_SVG_PLACE;
  let icon_path = null;
  const marker_category = categories.filter(category=>category.id === marker.category_id)[0];
  if(marker_category && marker_category.icon_code && CATEGORY_ICONS.filter(icon => icon.key===marker_category.icon_code).length > 0){
     icon_path = CATEGORY_ICONS.filter(icon => icon.key===marker_category.icon_code)[0].icon_path;
    if(marker_category.icon_code === 'categories_event')
      svg_icon_template = CATEGORY_ICON_SVG_EVENT;
  }
  if(marker_category && marker_category.colors){
    let color = marker_category.colors?JSON.parse(marker_category.colors).primary: "rgba(36, 51, 36, 1)";
    let svg = svg_icon_template.replace('{{CATEGORY_COLOR}}',color);
    if(icon_path)
    svg = svg.replace('{{CATEGORY_ICON}}',icon_path);
    return btoa(unescape(encodeURIComponent(svg)));
  }
  else
  return '';
}

//To replace the svg url for all items
export function setSvgURL(items, categories){
  items.map(item =>{
    item.svgURL = replaceMarkerIcon(item,categories);
    return true;
  });
  return items;
}
//To sort & search the items
export function sortAndSearch(objects, search_query){

  const searchFields = ['name','description'];

  //Sorting
  let sorted_objects = objects.sort((a,b) =>{
    if(a.featured === 1 && b.featured === 1
      && a.featuring_rating !== null && b.featuring_rating !== null
      && a.featuring_rating > b.featuring_rating)
    return -1;
    else
    return 1;
  //  return (a.updated_at > b.updated_at)?-1:1

  });

  //Searching
  if(search_query && search_query !== ''){
      sorted_objects = sorted_objects.filter((row) => {
        let found = false;
        searchFields.map((searchField) => {
          if(String(row[searchField]).search(new RegExp(search_query, "i")) > -1)
            found = true;
          else if(row['address'] !== null && String(row['address']['address_street']).search(new RegExp(search_query, "i")) > -1)
            found = true;
          else if(row['address'] !== null && String(row['address']['district']).search(new RegExp(search_query, "i")) > -1)
            found = true;
          return true;
        });
        return found;
      });
  }


  return sorted_objects
}

//To get the parent category
export function getParentCategory(category_slug, categories){
  let parent_category = null;
  if(categories.filter(category => category.slug === category_slug && category.parent_id === null).length > 0)
    parent_category = categories.filter(category => category.slug === category_slug && category.parent_id === null)[0];
  else{
    categories.filter(category => category.parent_id === null).map(category=>{
      if(category.descendants.filter(child => child.slug === category_slug).length > 0)
        parent_category = category;
      return true;
    });
  }
  return parent_category;
}

//To get the parent category of item
export function getParentCategoryOfItem(item, categories){
  let parent_category = null;
  if(categories.filter(category => category.id === item.category_id && category.parent_id === null).length > 0)
    parent_category = categories.filter(category => category.id === item.category_id && category.parent_id === null)[0];
  else{
    categories.filter(category => category.parent_id === null).map(category=>{
      if(category.descendants.filter(child => child.id === item.category_id).length > 0)
        parent_category = category;
      return true;
    });
  }
  return parent_category;
}
